export const MessageCommands = {

  CURRENT_HASH_LOCATION_COMMAND: "CurrentHashLocation",

  CURRENT_SOURCE_LOCATION_COMMAND: "CurrentSourceLocation",

  IS_AUTHENTICATED_COMMAND: "IsAuthenticated",

  DISPLAY_TERMS_OF_SERVICE_COMMAND: "DisplayTermsOfService"

}

export function postCurrentHashLocationState(currentHashLocation) {

  window.top.postMessage({
      name: MessageCommands.CURRENT_HASH_LOCATION_COMMAND,
      value: currentHashLocation
    },
    '*');

}

export function postCurrentSourceLocationState(currentSourceLocation) {

  window.top.postMessage({
      name: MessageCommands.CURRENT_SOURCE_LOCATION_COMMAND,
      value: currentSourceLocation
    },
    '*');

}

export function postAuthenticationState(isAuthenticated) {

  window.top.postMessage({
      name: MessageCommands.IS_AUTHENTICATED_COMMAND,
      value: isAuthenticated
    },
    '*');

}

export function postDisplayTermsOfServiceState() {

  window.top.postMessage({
      name: MessageCommands.DISPLAY_TERMS_OF_SERVICE_COMMAND
    },
    '*');

}






