import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Constants } from "../core/helpers/constants";
import { IframePipeInput } from "../core/models/iframe-pipe-input.model";
import { PodModel } from '../core/models/pod.model';
import { BrowserService } from "../core/services/Browser/browser.service";
import { DataService } from "../core/services/Data/data.service";
import { EventMessageHandlerService } from "../core/services/EventMessageHandler/event-message-handler.service";
import { PodAndSrcService } from "../core/services/PodAndSrc/pod-and-src.service";
import { UtilityService } from "../core/utilities/utility.service";

@Component({
  selector: 'app-embedded-kong',
  templateUrl: './embedded-kong.component.html',
  styleUrls: ['./embedded-kong.component.scss']
})

export class EmbeddedKongComponent implements OnInit, OnDestroy  {
  public iframePipeInput: IframePipeInput;

  public iframePipeInputSubscription: any;


  constructor(private browserService: BrowserService,
    private dataService: DataService,
    private eventMessageHandlerService: EventMessageHandlerService,
    private utility: UtilityService,
    private podAndSrcService: PodAndSrcService,
    private location: Location
  ) {
    this.location.subscribe(() => {

      let currUrl = this.getCurrUrl();

      let currPod = this.getCurrPod();

      let srcPath = this.utility.getSrcPathByUrl(currUrl);

      let srcHash = this.utility.getSrcHashByUrl(currUrl);

      if (currPod.value === this.utility.getPodByUrl(currUrl).value) {

        window.history.back();

        this.nextPipeInput(
          {
            command: Constants.COMMANDS.PROCESS_BROWSER_NAVIGATION,
            currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION),
            srcPath: srcPath,
            srcHash: srcHash
          }
        );

      } else {

        this.nextPipeInput(
          {
            command: Constants.COMMANDS.PROCESS_ILLEGAL_POD_CHANGE,
            currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION)
          }
        );
      }
    });
  }
  

  ngOnInit() {
    this.iframePipeInputSubscription = this.dataService.currPipeInput.subscribe(
      pipeInput => {
        this.iframePipeInput = pipeInput;
      }, error => {
        console.error(error + "failed to fetch iframe pipe input in embedded-kong.component");
      }
    );
  }

  public iframeOnLoad(): void {

    this.addEventListener(Constants.MESSAGE_EVENT_LISTENER, this.eventMessageHandlerService.eventMessageHandler);

  };


  private getCurrUrl(): string {
    return this.browserService.getCurrUrl();
  }

  private getCurrPod(): PodModel {
    return this.podAndSrcService.getCurrPod();
  }

  private addEventListener(listener, messageHandler): void {
    this.browserService.addEventListener(listener, messageHandler);
  }

  private removeEventListener(listener, messageHandler): void {
    this.browserService.removeEventListener(listener, messageHandler);
  }

  private getSession(sessionId: string) {
    return this.browserService.getSession(sessionId);
  }

  private nextPipeInput(pipeInput: IframePipeInput) {
    this.dataService.nextPipeInput(pipeInput);
  }


  ngOnDestroy() {
    this.iframePipeInputSubscription.unsubscribe();
    this.removeEventListener(Constants.MESSAGE_EVENT_LISTENER, this.eventMessageHandlerService.eventMessageHandler);
  };
}
