export class Constants {

  public static PORTAL_URL_API_ENDPOINT_LOCALHOST = 'https://lytx-developer-portal-api.int.drivecaminc.xyz/settings/GetPortalUrls';

  public static AUTHENTICATED = 'authenticated';

  public static NOT_AUTHENTICATED = 'not authenticated';

  public static AUTH_ENDPOINT = 'Account/Login';

  public static HASH_SYMBOL = '#';

  public static ENVIRONMENT_API_ENDPOINT = '/environment.json';

  public static PORTAL_URL_API_ENDPOINT_ROUTE = '/settings/GetPortalUrls';

  public static LOCAL_HOST = 'localhost';

  public static MESSAGE_EVENT_LISTENER = 'message';

  public static TOS_PATH = 'default/terms-of-service';

  public static CURRENT_SOURCE_LOCATION = 'CurrentSourceLocation';

  public static LOCAL_STORAGE_POD_KEY = "SelectedPod";

  public static LOCAL_STORAGE_AUTHENTICATED_POD_KEY = "authenticated pod";

  public static COMMANDS = {

    CHANGE_POD: 'change pod',

    GO_TO_HOME_PAGE: 'go to home page',

    PROCESS_ILLEGAL_POD_CHANGE: 'process illegal pod change',

    PROCESS_BROWSER_NAVIGATION: 'process browser navigation',

    GO_TO_TOS: 'go to terms of service'
  }

  public static EVENT_KEYS = {

    CURR_SRC: 'currSrc'

  }
}
