import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserService } from "../../services/Browser/browser.service";
import { PodAndSrcService } from "../../services/PodAndSrc/pod-and-src.service";
import { AuthenticatedPodsHandlerService } from "../../services/AuthenticatedPodsHandler/authenticated-pods-handler.service";
import { UtilityService } from "../../../core/utilities/utility.service";
import { Constants } from "../../../core/helpers/constants";
import { IframePipeInput } from "../../../core/models/iframe-pipe-input.model";
import { PodModel } from "../../../core/models/pod.model";

@Pipe({
  name: 'iframePipe'
})
export class IframePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer,
    private browserService: BrowserService,
    private podAndSrcService: PodAndSrcService,
    private authenticatedPodsHandlerService: AuthenticatedPodsHandlerService,
    private utility: UtilityService) {
  }

  transform(pipeInput: IframePipeInput): any {

    var defaultSrc : string = this.getDefaultSrc();

    if (pipeInput === null) {

      let currUrl : string = this.browserService.getCurrUrl();

      let pod : PodModel = this.utility.getPodByUrl(currUrl); 

      let localPod : PodModel = this.browserService.getLocal(Constants.LOCAL_STORAGE_POD_KEY);

      let baseUrl : string = null;

      if(pod)
      {
        baseUrl = this.getBaseUrl(pod);
      }
      else if(localPod)
      {
        baseUrl = this.getBaseUrl(localPod);
      }
      
      if(!baseUrl) 
      {
        baseUrl = defaultSrc;
      }

      let srcPath: string = this.utility.getSrcPathByUrl(currUrl);

      let srcHash: string = this.utility.getSrcHashByUrl(currUrl);

      let authenticatedPod = this.authenticatedPodsHandlerService.getAuthenticatedPod();

      if (authenticatedPod) {
        baseUrl = this.getBaseUrl(authenticatedPod);
      }

      return this.sanitizeUrl(baseUrl + '/' + srcPath + srcHash);
    }

    var command = pipeInput.command;

    var currSrc = pipeInput.currSrc;

    var currPod = pipeInput.currPod;

    var srcPath = pipeInput.srcPath;

    var srcHash = pipeInput.srcHash;

    var baseUrl = this.utility.getBase(currSrc);

    var locationUrl = this.utility.getLocation(currSrc);

    switch(command) {

    case Constants.COMMANDS.GO_TO_HOME_PAGE:

      return this.sanitizeUrl(baseUrl);
      break;

    case Constants.COMMANDS.CHANGE_POD:
        
      baseUrl = this.getBaseUrl(currPod);
      return this.sanitizeUrl(baseUrl + '/' + locationUrl);
      break;

    case Constants.COMMANDS.PROCESS_BROWSER_NAVIGATION:

      return this.sanitizeUrl(baseUrl + '/' + srcPath + srcHash);
      break;

    case Constants.COMMANDS.PROCESS_ILLEGAL_POD_CHANGE:

      return this.sanitizeUrl(currSrc);
      break;

    case Constants.COMMANDS.GO_TO_TOS:
      return this.sanitizeUrl(baseUrl + '/' + srcPath);
      break;

    default:
      return this.sanitizeUrl(currSrc);
    }
  }

  private getDefaultSrc() {
    return this.podAndSrcService.getDefaultSrc();
  }

  private sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private getBaseUrl(pod: PodModel) {
    return this.podAndSrcService.getBaseSrc(pod);
  }

}
