import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Constants } from '../../../core/helpers/constants';
import { IframePipeInput } from '../../models/iframe-pipe-input.model';
import { PodModel } from '../../models/pod.model';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  public pod = new BehaviorSubject(null);
  public pipeInput = new BehaviorSubject(null);
  public userAuthStatus = new BehaviorSubject(Constants.NOT_AUTHENTICATED);

  public currPod = this.pod.asObservable();
  public currPipeInput = this.pipeInput.asObservable();
  public currUserAuthStatus = this.userAuthStatus.asObservable();
  private authStatus = Constants.NOT_AUTHENTICATED;

  constructor() {
  }

  public nextPod(pod: PodModel) {
    this.pod.next(pod);
  }

  public nextPipeInput(pipeInput: IframePipeInput) {
    this.pipeInput.next(pipeInput);
  }

  public nextUserAuthStatus(authStatus: string) {
    this.authStatus = authStatus;
    this.userAuthStatus.next(authStatus);
  }

  public getCurrAuthStatus() {
    return this.authStatus;
  }
}

