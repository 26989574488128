import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../Data/data.service';
import { PodAndSrcService } from '../PodAndSrc/pod-and-src.service';
import { BrowserService } from '../Browser/browser.service';
import { UtilityService } from '../../utilities/utility.service';
import { AuthenticatedPodsHandlerService } from '../AuthenticatedPodsHandler/authenticated-pods-handler.service';
import { Constants } from "../../helpers/constants";
import { EventMessage } from "../../models/event-message.model";
import { IframePipeInput } from "../../models/iframe-pipe-input.model";
import { PodModel } from "../../models/pod.model";
import { MessageCommands } from '../../../../assets/files/postmsg.js';

@Injectable({
  providedIn: 'root'
})
export class EventMessageHandlerService {

  srcPath = '';
  podNumber = '';

  constructor(private dataService: DataService,
    private browserService: BrowserService,
    private utility: UtilityService,
    private podAndSrcService: PodAndSrcService,
    private authenticatedPodsHandlerService: AuthenticatedPodsHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  public eventMessageHandler = (event: any) => {

    var eventMessage: EventMessage = event.data;

    switch (eventMessage.name) {

        case MessageCommands.CURRENT_HASH_LOCATION_COMMAND:
          this.updateHash(eventMessage.value);
          break;

        case MessageCommands.CURRENT_SOURCE_LOCATION_COMMAND:
          this.updateCurrSrc(eventMessage.value);
          break;

        case MessageCommands.IS_AUTHENTICATED_COMMAND:
          this.updateAuthStatus(eventMessage.value);
          break;

        case MessageCommands.DISPLAY_TERMS_OF_SERVICE_COMMAND:
          this.directToTos();
          break;

        default:
          break;
      }
  }


  private updateHash(hash: any) {

    this.updateQueryParameters(this.srcPath, this.podNumber, hash);

  }

  private updateCurrSrc(currSrc: any): void {

    if (!currSrc.includes(Constants.AUTH_ENDPOINT)) {

      this.updateUserData(currSrc);

    } else {

      if (this.getAuthStatus() === Constants.AUTHENTICATED) {

        this.redirectToHomePage();

      }
    }
  }

  private updateAuthStatus(isAuthenticated: any): void {

    var authStatus = (isAuthenticated === "true") ? Constants.AUTHENTICATED : Constants.NOT_AUTHENTICATED;

    this.nextUserAuthStatus(authStatus);

    this.authenticatedPodsHandlerService.updateAuthenticatedPod(authStatus);
  }

  private directToTos() {
    this.nextPipeInput(
      {
        command: Constants.COMMANDS.GO_TO_TOS,
        currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION),
        srcPath: Constants.TOS_PATH
      });
  }

  private redirectToHomePage() {
    this.nextPipeInput(
      {
        command: Constants.COMMANDS.GO_TO_HOME_PAGE,
        currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION)
      }
    );
  }

  private updateUserData(currSrc: string) {

    var pod = this.getPod(this.utility.getBase(currSrc));

    this.nextPod(pod);

    this.srcPath = this.utility.getLocation(currSrc);

    this.podNumber = pod.value.replace('pod ', '');

    this.setSession(Constants.CURRENT_SOURCE_LOCATION, currSrc.split(Constants.HASH_SYMBOL)[0]);

    this.updateQueryParameters(this.srcPath, this.podNumber, null);

  }


  private updateQueryParameters(path: string, pod: string, hash: string) {

    this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          path: path,
          pod: pod,
          hash: hash
        },
        queryParamsHandling: 'merge'
      });
  }

  private nextPipeInput(pipeInput: IframePipeInput) {
    this.dataService.nextPipeInput(pipeInput);
  }

  private nextUserAuthStatus(status: string) {
    this.dataService.nextUserAuthStatus(status);
  }

  private nextPod(pod: PodModel) {
    this.dataService.nextPod(pod);
  }

  private getPod(baseSrc): PodModel {
    return this.podAndSrcService.getPod(baseSrc);
  }

  private getAuthStatus(): string {
    return this.dataService.getCurrAuthStatus();
  }

  private getSession(sessionKey: string): string {
    return this.browserService.getSession(sessionKey);
  }

  private setSession(sessionKey: string, sessionValue: any) {
    this.browserService.setSession(sessionKey, sessionValue);
  }

}
