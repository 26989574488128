import { Injectable } from '@angular/core';
import { BrowserService } from '../Browser/browser.service';
import { UtilityService } from '../../utilities/utility.service';
import { Constants } from "../../helpers/constants";
import { PodModel } from "../../models/pod.model";

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedPodsHandlerService {

  constructor(private browserService: BrowserService,
    private utility: UtilityService) { }


  public updateAuthenticatedPod(authStatus: string) : void {

    if (authStatus === Constants.AUTHENTICATED) {

      let currUrl: string = this.browserService.getCurrUrl();

      let pod: PodModel = this.utility.getPodByUrl(currUrl);

      this.browserService.setLocal(Constants.LOCAL_STORAGE_AUTHENTICATED_POD_KEY, JSON.stringify(pod));

    } else {

      this.browserService.setLocal(Constants.LOCAL_STORAGE_AUTHENTICATED_POD_KEY, null);

    }
  }

  public getAuthenticatedPod() : PodModel {

    let authenticatedPodLocal: PodModel = JSON.parse(this.browserService.getLocal(Constants.LOCAL_STORAGE_AUTHENTICATED_POD_KEY));

    let authenticatedPod = null;

    if (authenticatedPodLocal) {
      authenticatedPod = authenticatedPodLocal;
    }

    return authenticatedPod;

  }

}
