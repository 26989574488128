import { Injectable } from '@angular/core';
import { PodModel } from '../models/pod.model';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private LYTX_API = "Lytx API: "; 

  constructor() { }

  createMappingFromBaseSrcToPod(mappingFromPodToBaseSrc) {
    var mappingFromBaseSrcToPod = {};
    for (let pod in mappingFromPodToBaseSrc) {
      mappingFromBaseSrcToPod[mappingFromPodToBaseSrc[pod]] = pod;
    }
    return mappingFromBaseSrcToPod;
  }

  getBase(url) : string {
    return url.split('/').slice(0, 3).join('/');
  }

  getLocation(url): string {
    if (url.includes('#')) {
      url = url.split('#')[0];
    }
    return url.split('/').slice(3, url.split('/').length).join('/');
  }

  getSrcPathByUrl(url): string {
    return new URL(url).searchParams.get('path') || '';
  }

  getSrcHashByUrl(url): string {
    return new URL(url).searchParams.get('hash') || '';
  }

  getPodByUrl(url): PodModel {
    var podNumber = new URL(url).searchParams.get('pod');

    if (podNumber === null) {
      return null;
    }

    var podValue = 'pod ' + podNumber;

    var podText = this.LYTX_API + podValue;

    var pod: PodModel = {
      value: podValue,
      text: podText
    }

    return pod;
  }

}
