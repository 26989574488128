import { Component, OnInit, OnDestroy } from "@angular/core";
import { BrowserService } from "../../../core/services/Browser/browser.service";
import { PodAndSrcService } from "../../../core/services/PodAndSrc/pod-and-src.service";
import { DataService } from "../../../core/services/Data/data.service";
import { PodModel } from "../../../core/models/pod.model";
import { IframePipeInput } from "../../../core/models/iframe-pipe-input.model";
import { Constants } from "../../../core/helpers/constants";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public selectedPod: string;
  public podPickerList: PodModel[] = [];
  public userAuthStatus: string;
  public constants: Constants = Constants;

  public currPodSubscription: any;
  public userAuthStatusSubscription: any;

  constructor(private browserService: BrowserService,
    private podAndSrcService: PodAndSrcService,
    private dataService: DataService) {
  }
  
  ngOnInit() {
    this.initializePodPicker();

    this.currPodSubscription = this.dataService.currPod.subscribe(
      pod => {
        if (pod !== null) {
          this.selectedPod = pod.text;
          this.browserService.setLocal(Constants.LOCAL_STORAGE_POD_KEY, pod);
        }
      },
      error => {
        console.error(error + ' Error fetching current pod in header.component');
      });

    this.userAuthStatusSubscription = this.dataService.currUserAuthStatus.subscribe(
      userAuthStatus => {
        this.userAuthStatus = userAuthStatus;
      },
      error => {
        console.error(error + ' Error fetching current user authentication status in header.component');
      });
  }

  public changePod(): void {
    this.nextPipeInput(this.getIframeInputForChangePod());
  }

  public logoOnClick(): void {
    this.nextPipeInput(this.getIframeInputForLogoOnClick());
  }

  private getIframeInputForChangePod(): IframePipeInput {

    var pod: PodModel = this.podPickerList.find((element) => element.text === this.selectedPod);

    var iframeInput: IframePipeInput = {
      command: Constants.COMMANDS.CHANGE_POD,
      currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION),
      currPod: pod
    };

    this.browserService.setLocal(Constants.LOCAL_STORAGE_POD_KEY, pod);

    return iframeInput;
  }

  private getIframeInputForLogoOnClick(): IframePipeInput {

    var iframeInput: IframePipeInput = {
      command: Constants.COMMANDS.GO_TO_HOME_PAGE,
      currSrc: this.getSession(Constants.CURRENT_SOURCE_LOCATION)
    };

    return iframeInput;
  } 

  private initializePodPicker() {

    this.podPickerList = this.getPods();

    //TODO: Update to use cache, then default back
    if (this.podPickerList.length > 0) {
      var localPod = this.browserService.getLocal(Constants.LOCAL_STORAGE_POD_KEY);

      if(localPod)
      {
        var localPodSelection = this.podPickerList.find(p => localPod === p);

        if(localPodSelection)
        {
          this.selectedPod = localPod.text;
        }
      }
      else
      {
        this.selectedPod = this.podPickerList[0].text;
        this.browserService.setLocal(Constants.LOCAL_STORAGE_POD_KEY, this.selectedPod);
      }
    }
  }

  private getPods() : PodModel[] {
    return this.podAndSrcService.getPods();
  }

  private getSession(sessionId: string) : any {
    return this.browserService.getSession(sessionId);
  }

  private nextPipeInput(iframeInput: IframePipeInput): void {
    this.dataService.nextPipeInput(iframeInput);
  }

  ngOnDestroy() {
    this.currPodSubscription.unsubscribe();
    this.userAuthStatusSubscription.unsubscribe();
  }
}







