import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms'; 
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { EmbeddedKongComponent } from './embedded-kong/embedded-kong.component';
import { IframePipe } from './core/pipes/iframe/iframe.pipe';
import { PodAndSrcService } from './core/services/PodAndSrc/pod-and-src.service';

export function initializePodMapping(podAndSrcService: PodAndSrcService) {
  const init = (): Promise<any> => {
    return podAndSrcService.loadPortalUrls();
  }
  return init;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    EmbeddedKongComponent,
    IframePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [PodAndSrcService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializePodMapping,
      deps: [PodAndSrcService],
      multi: true
    }],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
