import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from "../../helpers/constants";
import { EnvironmentVariables } from '../../models/environment-variables.model';
import { PodModel } from '../../models/pod.model';
import { UtilityService } from "../../utilities/utility.service";
import { BrowserService } from "../../services/Browser/browser.service";

@Injectable({
  providedIn: 'root'
})
export class PodAndSrcService {

  constructor(private http: HttpClient,
    private utility: UtilityService,
    private browserService: BrowserService) {
  }

  private mappingFromPodToBaseSrc;
  private mappingFromBaseSrcToPod;
  private LYTX_API = "Lytx API: ";


  public getPod(baseSrc: string): PodModel {

    var pod: PodModel = new PodModel();

    pod.value = this.mappingFromBaseSrcToPod[baseSrc];

    pod.text = this.LYTX_API + pod.value;

    return pod;
  }

  public getPods(): PodModel[] {

    var podValues = Object.keys(this.mappingFromPodToBaseSrc);

    var pods: PodModel[] = [];

    for (let podValue of podValues) {

      var pod: PodModel = new PodModel();

      pod.value = podValue;

      pod.text = this.LYTX_API + pod.value;

      pods.push(pod);
    }

    return pods;
  }

  public getDefaultSrc() {

    var defaultPod = Object.keys(this.mappingFromPodToBaseSrc)[0];

    return this.mappingFromPodToBaseSrc[defaultPod];
  }

  public getBaseSrc(pod: PodModel) {

    return this.mappingFromPodToBaseSrc[pod.value];

  }

  public getCurrPod() {

    let baseUrl = this.utility.getBase(this.getSession(Constants.CURRENT_SOURCE_LOCATION));

    return this.getPod(baseUrl);
  }

  public loadPortalUrls(): Promise<any> {

    var windowLocation = this.getWindowLocation();

    if (windowLocation.includes(Constants.LOCAL_HOST)) {

      var errorMessage = "failed to fetch portal url data in localhost";

      return this.loadPortalUrlData(Constants.PORTAL_URL_API_ENDPOINT_LOCALHOST, errorMessage);
    } else {

      return this.getEnvironmentVariables(windowLocation).then((environmentVariables: EnvironmentVariables) => {

        var portalUrlApiEndpoint = environmentVariables.Application__Lytx_Developer_Portal_API__URL +

          Constants.PORTAL_URL_API_ENDPOINT_ROUTE;

        var errorMessage = "failed to fetch portal url data from lytx-developer-portal-api";

        return this.loadPortalUrlData(portalUrlApiEndpoint, errorMessage);
      });
    }
  }

  public setMappingFromPodToBaseUrl(mappingFromPodToBaseSrc) {

    this.mappingFromPodToBaseSrc = mappingFromPodToBaseSrc;
  }

  public setMappingFromBaseUrlToPod(mappingFromBaseSrcToPod) {

    this.mappingFromBaseSrcToPod = mappingFromBaseSrcToPod;
  }

  private getEnvironmentVariables(windowLocation): Promise<Object> {
    try {

      windowLocation = this.utility.getBase(windowLocation) + '/';

      return this.http.get(windowLocation + Constants.ENVIRONMENT_API_ENDPOINT).toPromise();
    } catch (e) {

      console.error(e, 'failed to get environment variables');
    }
  }

  private loadPortalUrlData(portalUrlApiEndpoint, errorMessage): Promise<void> {

    return this.http.get(portalUrlApiEndpoint).toPromise().then(

      (rawPortalUrlData: Object) => {

        this.setMappings(rawPortalUrlData);

      }, (error) => console.error(errorMessage)
    );
  }

  private setMappings(rawPortalUrlData: Object): void {

    this.mappingFromPodToBaseSrc = rawPortalUrlData;

    this.mappingFromBaseSrcToPod = this.createMappingFromBaseSrcToPod(this.mappingFromPodToBaseSrc);

  }

  private createMappingFromBaseSrcToPod(mappingFromPodToBaseUrl: any): Object {

    return this.utility.createMappingFromBaseSrcToPod(mappingFromPodToBaseUrl);
  }

  private getWindowLocation(): any {

    return this.browserService.getCurrUrl();
  }

  private getSession(sessionKey: string): string {

    return this.browserService.getSession(sessionKey);
  }

}
